<template>
  <section>
    <h4 class="text-center text-md-left mb-3">Repeat Orders</h4>

    <div class="card-columns">
      <div class="card mr-2">
        <div class="card-header">New Repeat Order</div>
        <div class="card-body" id="edit-form">
          <b-form-group label="Customer">
            <v-select v-model="order.customer.id"
                      @search="searchCustomers"
                      :disabled="order.id != null"
                      :options="customers"
                      :reduce="p => p.id"
                      @input="loadAddress"
                      label="displayName"></v-select>

          </b-form-group>

          <b-form-group label="Address">
            <v-select v-model="order.address.id" :options="addresses"
                      :reduce="p => p.id"
                      :disabled="order.id != null"
                      label="fullAddress"></v-select>
          </b-form-group>
          <b-form-group label="Product">
            <v-select :options="allProducts"
                      :disabled="order.id != null"

                      :reduce="p => p.id" v-model="order.product.id" label="name"></v-select>

          </b-form-group>

          <b-form-group label="variant">
            <b-form-select v-model="order.variant.id"
                           :options="variants"
                           :disabled="order.id != null"

                           value-field="id"
                           text-field="name"
            ></b-form-select>
          </b-form-group>

          <b-form-group label="Qty">
            <b-form-input type="number" v-model="order.qty"></b-form-input>
          </b-form-group>
          <b-form-group label="Frequency">
            <b-form-select :options="freqOptions" v-model="order.frequency"
                           value-field="v" text-field="t"
            ></b-form-select>
          </b-form-group>

          <b-form-group label="Brand">
            <b-form-select v-model="order.brandId"
                           placeholder=""
                           :options="$store.state.brands"
                           value-field="id" text-field="name"
            ></b-form-select>

          </b-form-group>

          <b-form-group label="Days of Week"
                        v-if="order.frequency !== 'MONTHLY' && order.frequency !== 'SPECIFIC_WEEKDAY'">
            <b-form-checkbox-group
                id="checkbox-group-1"
                v-model="order.days"
                name="flavour-1">

              <b-form-checkbox v-for="(wd, idx) of weekDays" :key="idx" :value="idx+1">{{ wd }}</b-form-checkbox>

            </b-form-checkbox-group>
          </b-form-group>
          <b-form-group label="Days of Month" v-else>

            <b-form-select :options="monthDays" value-field="k" text-field="v" multiple
                           v-model="order.days"></b-form-select>

          </b-form-group>

        </div>
        <div class="card-footer d-flex justify-content-end">
          <b-button-group size="sm">
            <b-button @click="cancel" variant="warning">
              <font-awesome-icon icon="times"></font-awesome-icon>
              Cancel
            </b-button>
            <b-button @click="save" variant="success">
              <font-awesome-icon icon="save"></font-awesome-icon>
              Save
            </b-button>
          </b-button-group>
        </div>
      </div>
      <div class="card mr-2" v-for="o of orders" :key="o.id">
        <div class="card-body d-flex flex-column">
          <div class="d-flex justify-content-between align-content-center align-items-center">

            <div class="flex-grow-1 d-flex align-content-center align-items-center">
              <div class="mr-1">
                <display-brand v-if="o.brand" :id="o.brand.id" :name="false" :width="64"
                               :border="false"></display-brand>
              </div>
              <customer-avatar :customer="o.customer" size="4em"></customer-avatar>
            </div>

            <div class="d-flex flex-column align-items-end text-right">
              <div style="font-size: large">{{ o.customer.name }}</div>
              <div style="max-width: 20em; font-size: small">{{ o.address.door }}, {{ o.address.apartment }},
                {{ o.address.address }}
              </div>
            </div>
          </div>

          <div class="list-group mt-3">
            <div class="list-group-item d-flex justify-content-between">
              <span>{{ o.product.name }} &ndash; {{ o.productVariant.name }}</span>
              <span>{{ o.qty }}</span>
            </div>
            <div class="list-group-item d-flex justify-content-between">
              <strong>{{ getFr(o.frequency) }}</strong>
              <span v-if="o.frequency !== 'MONTHLY' && o.frequency !== 'SPECIFIC_WEEKDAY'">
                <span class="ml-2" v-for="idx of o.days" :key="idx">{{ weekDays[idx - 1] }}</span>
              </span>
              <span v-else>
                <span class="ml-2" v-for="idx of o.days" :key="idx">{{ getWD(idx) }}</span>
              </span>
            </div>
          </div>
        </div>

        <div class="card-footer d-flex justify-content-end">
          <b-button-group>
            <b-button @click="deleteOrd(o)" variant="danger">
              <font-awesome-icon icon="times"></font-awesome-icon>
              Delete
            </b-button>
            <b-button @click="pauseOrd(o)" :variant="o.paused ? 'info' : 'warning'">
              <font-awesome-icon :icon="o.paused ? 'play' : 'pause'"></font-awesome-icon>
              <span v-if="o.paused"> Resume</span>
              <span v-else> Pause</span>
            </b-button>
            <b-button @click="editOrd(o)" variant="primary">
              <font-awesome-icon icon="pencil"></font-awesome-icon>
              Edit
            </b-button>
          </b-button-group>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from 'axios'
import CustomerAvatar from '@/components/customer-avatar'
import { NewRepeatOrder } from '@/util'
import DisplayBrand from '@/components/display-brand'

export default {
  name: 'RepeatOrders',
  components: {DisplayBrand, CustomerAvatar},
  data: () => ({
    freqOptions: [
      {v: 'SPECIFIC_WEEKDAY', t: 'On Specific Days Of Month'},
      {v: 'MONTHLY', t: 'Once a Month'},
      {v: 'WEEKLY', t: 'On Days Of a Week'},
      {v: 'BI_WEEKLY', t: 'Once in 2 Weeks'},
      {v: 'DAILY', t: 'On All Working Days'},
    ],
    orders: [],
    customers: [],
    order: new NewRepeatOrder(),
    addresses: [],
    weekDays: ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
    monthDays: [
      {k: 1, v: '1st'},
      {k: 2, v: '2nd'},
      {k: 3, v: '3rd'},
      {k: 4, v: '4th'},
      {k: 5, v: '5th'},
      {k: 6, v: '6th'},
      {k: 7, v: '7th'},
      {k: 8, v: '8th'},
      {k: 9, v: '9th'},
      {k: 10, v: '10th'},
      {k: 11, v: '11th'},
      {k: 12, v: '12th'},
      {k: 13, v: '13th'},
      {k: 14, v: '14th'},
      {k: 15, v: '15th'},
      {k: 16, v: '16th'},
      {k: 17, v: '17th'},
      {k: 18, v: '18th'},
      {k: 19, v: '19th'},
      {k: 20, v: '20th'},
      {k: 21, v: '21st'},
      {k: 22, v: '22nd'},
      {k: 23, v: '23rd'},
      {k: 24, v: '24th'},
      {k: 25, v: '25th'},
      {k: 26, v: '26th'},
      {k: 27, v: '27th'},
      {k: 28, v: '28th'},
      {k: 29, v: '29th'},
      {k: 30, v: '30th'},
      '31st'
    ]
  }),
  computed: {

    allProducts () {
      return this.$store.state.categories.flatMap(c => c.products)
    },
    variants () {
      let li = this.order
      if (li.product && li.product.id) {
        return this.$store.state.products
            .filter(p => p.id === li.product.id)[0].variants
      }
      return []
    },
  },
  async created () {
    await this.load()
  },
  methods: {
    getFr(f){
      let wd = this.freqOptions.filter(fr => fr.v === f)
      return wd.length > 0 ? wd[0].t : f
    },
    getWD (fd) {
      let wd = this.monthDays.filter(w => w.k === fd)
      return wd.length > 0 ? wd[0].v : fd
    },
    async load () {
      this.orders = (await axios.get('/api/admin/repeat-order')).data
    },
    async save () {
      await axios.post('/api/admin/repeat-order', this.order)
      this.order = new NewRepeatOrder()
      await this.load()
    },
    async cancel () {
      this.order = new NewRepeatOrder()
      document.getElementById('edit-form').scrollIntoView(true)
    },
    async loadAddress () {
      let r = await axios.get('/api/admin/address?customer_id=' + this.order.customer.id)
      this.addresses = r.data
      if (this.addresses.length === 1) {
        this.order.address = {
          id: this.addresses[0].id,
          address: this.addresses[0].address
        }
      }
    },
    async searchCustomers (search, loading) {
      if (search.length > 3) {
        loading(true)
        let r = await axios.get('/api/admin/customers?search=' + search)
        this.customers = r.data
        loading(false)
      }
    },
    async deleteOrd (o) {
      await axios.delete('/api/admin/repeat-order?id=' + o.id)
      await this.load()
    },
    async pauseOrd (o) {
      await axios.put('/api/admin/repeat-order?id=' + o.id)
      await this.load()
    },
    async editOrd (o) {
      this.order = (await axios.get('/api/admin/repeat-order/edit?id=' + o.id)).data
      this.customers = [o.customer]
      this.addresses = [o.address]
      document.getElementById('edit-form').scrollIntoView(true)
    },
  }
}
</script>