<template id="print">
  <!-- select category and option to exclude some products-->
  <!-- optionally add description & ingredients -->
  <!-- optionally add images -->
  <!-- optionally add prices -->
  <!-- post to generate PDF -->
  <form :action="actionUrl" target="_blank" class="m-2" method="post">
    <h5>Product List Generator</h5>
    <input type="hidden" name="q" :value="sj">
    <div class="d-flex flex-column flex-md-row justify-content-center justify-content-md-between">
      <div class="d-flex flex-column">
        <b-form-checkbox v-model="s.images" switch>Include Images</b-form-checkbox>
        <b-form-checkbox v-model="s.prices" switch>Include Prices</b-form-checkbox>
        <b-form-checkbox v-model="s.ingredients" switch>Include Ingredients</b-form-checkbox>
        <b-form-checkbox v-model="s.description" switch>Include Description</b-form-checkbox>
        <b-form-checkbox v-model="s.allVariants" switch>All Variants?</b-form-checkbox>
        <b-form-checkbox v-model="s.outOfStock" switch>Include Out Of Stock</b-form-checkbox>
        <b-form-checkbox v-model="s.trialKit" switch>Include Trial Kit</b-form-checkbox>
      </div>
      <b-form-group label="Column Count">
        <b-form-input v-model="s.colCount" type="number"></b-form-input>
      </b-form-group>
      <b-form-group label="Output Type">
        <b-form-radio v-model="s.outputType" name="some-radios" value="html">HTML</b-form-radio>
        <b-form-radio v-model="s.outputType" name="some-radios" value="pdf">PDF</b-form-radio>
        <b-form-radio v-model="s.outputType" name="some-radios" value="jpeg">Image</b-form-radio>
      </b-form-group>
      <b-form-group label="Page Size">
        <b-form-radio v-model="s.pageSize" name="some-radios-1" value="A4">A4</b-form-radio>
        <b-form-radio v-model="s.pageSize" name="some-radios-1" value="A5">A5</b-form-radio>
        <b-form-radio v-model="s.pageSize" name="some-radios-1" value="A6">A6</b-form-radio>
      </b-form-group>
      <div class="d-flex align-items-center align-content-center">
        <div>
          <button type="submit" class="btn btn-info btn-lg">
            <i class="far fa-download"></i> Get Document
          </button>
        </div>
      </div>
    </div>
    <div class="card-columns mt-3">
      <b-card v-for="pc of allCategories" :key="pc.id" class="mr-md-2 mt-2">
        <template #header>
          <div class="d-flex flex-row align-items-center">
            <b-form-checkbox v-model="pc.selected" switch @change="toggleCat(pc)"></b-form-checkbox>
            <span class="ml-2" style="font-size: 1em">{{ pc.name }}</span>
          </div>
        </template>
        <div class="list-group">
          <div class="list-group-item" v-for="prod of pc.products" :key="prod.id">
            <b-form-checkbox v-model="prod.selected" switch @change="toggleProd(prod)"
                             :disabled="s.selectedCat.indexOf(pc.id) === -1">
              {{ prod.name }}
            </b-form-checkbox>
          </div>
        </div>
      </b-card>
    </div>
  </form>
</template>
<script>
export default {
  name: 'print-products-list',
  data: () => ({
    allCategories: [],
    s: {
      selectedCat: [],
      selectedProduct: [],
      images: false,
      allVariants: false,
      prices: true,
      trialKit: true,
      colCount: 1,
      description: true,
      outOfStock: true,
      ingredients: true,
      outputType: 'pdf',
      pageSize: 'A4'
    }
  }),
  created() {
    this.loadProducts()
  },
  computed: {
    sj() {
      return JSON.stringify(this.s)
    },
    actionUrl() {
      return process.env.VUE_APP_URL + '/public/products'
    }
  },
  methods: {
    toggleCat(p) {
      let number = this.s.selectedCat.indexOf(p.id);
      if (number === -1) {
        this.s.selectedCat.push(p.id)
        //simply make all products active
        p.products.forEach(prd => {
          if (this.s.selectedProduct.indexOf(prd.id) === -1) {
            this.s.selectedProduct.push(prd.id)
            prd.selected = true
          }
        })
      } else {
        this.s.selectedCat.splice(number, 1)
      }
    },
    toggleProd(p) {
      let number = this.s.selectedProduct.indexOf(p.id);
      if (number === -1) {
        this.s.selectedProduct.push(p.id)
      } else {
        this.s.selectedProduct.splice(number, 1)
      }
    },
    async loadProducts() {
      let res = await fetch(process.env.VUE_APP_URL + '/public/categories')
      let p = await res.json()
      this.allCategories = p.map(pc => {
        pc.selected = false
        pc.products = pc.products.map(prd => {
          prd.selected = false
          return prd
        })
        return pc
      })
      this.s.selectedCat = []
      this.s.selectedProduct = []
    }
  }
}
</script>