<template>
  <section>
    <div class="d-flex justify-content-md-between flex-column flex-md-row mb-3">
      <h1>Partners</h1>

      <div class="div mt-3 mt-md-0">
        <div class="btn-group btn-group-sm">

          <b-button @click="editPartner(null)">
            <font-awesome-icon icon="plus"></font-awesome-icon>
            Add New Partner
          </b-button>
          <b-button @click="loadPartners" variant="success">
            <font-awesome-icon icon="sync"></font-awesome-icon>
            Refresh
          </b-button>
          <b-button @click="message" variant="warning">
            <font-awesome-icon icon="envelope"></font-awesome-icon>
            Message
          </b-button>
        </div>
      </div>
    </div>

    <div class="d-flex flex-column flex-md-row flex-wrap">
      <div class="card mr-md-3 mt-3" v-for="p of partners" :key="p.id" v-bind:class="{'bg-warning': !p.approved}">

        <div class="card-header">
          <font-awesome-icon icon="user"></font-awesome-icon>
          {{ p.name }}
        </div>
        <div class="card-body">
          <div class="list-group list-group-flush">
            <div class="list-group-item p-1 border-0">

            </div>
            <div class="list-group-item p-1 border-0">
              <a :href="'tel:+91'+p.mobile">
                <font-awesome-icon icon="phone"></font-awesome-icon>
                {{ p.mobile }}
              </a>
            </div>
            <div class="list-group-item p-1 border-0" v-if="p.email">
              <span v-bind:class="{'text-success': p.emailValidated, 'text-danger': !p.emailValidated}">
                <font-awesome-icon icon="envelope"></font-awesome-icon>
                {{ p.email }}
              </span>
            </div>

            <div class="list-group-item p-1 border-0">
              Cost per Post
              <rupee>{{ p.initPayment }}</rupee>
            </div>
            <div class="list-group-item p-1 border-0 d-flex"
                 v-bind:class="{'text-danger': !p.sentKit, 'text-success': p.sentKit}">
              <span class="mr-2">Kit Sent?</span>
              <span v-if="p.sentKit"><font-awesome-icon icon="check"></font-awesome-icon></span>
              <span v-else><font-awesome-icon icon="times"></font-awesome-icon></span>
            </div>

            <div class="list-group-item p-1 border-0" v-if="p.sampleRequest">
              Sample Kit : {{ p.sampleRequest }}
            </div>
            <div class="list-group-item p-2 border-0 list-group-item-info" v-if="p.notes">
              {{ p.notes }}
            </div>

            <div class="list-group-item" v-if="partner.brandId">
              <display-brand :id="partner.brandId"></display-brand>
            </div>
          </div>
          <div v-if="p.socialProfiles.instagram" class="list-group-item p-1 border-0">
            <a target="_blank" :href="p.socialProfiles.instagram">
              <font-awesome-icon :icon="['fab', 'instagram']"></font-awesome-icon>
              {{
                p.socialProfiles.instagram.replace('https://www.instagram.com/', '').replace('https://instagram.com/', '')
              }}
            </a>
          </div>
          <div v-if="p.socialProfiles.facebook" class="list-group-item p-1 border-0">
            <a target="_blank" :href="p.socialProfiles.facebook">
              <font-awesome-icon :icon="['fab', 'facebook']"></font-awesome-icon>
              {{
                p.socialProfiles.facebook.replace('https://www.facebook.com/', '').replace('https://facebook.com/', '')
              }}
            </a>
          </div>
          <div v-if="p.socialProfiles.youtube" class="list-group-item p-1 border-0">
            <a target="_blank" :href="p.socialProfiles.youtube">
              <font-awesome-icon :icon="['fab', 'youtube']"></font-awesome-icon>
              {{ p.socialProfiles.youtube.replace('https://www.youtube.com/', '').replace('https://youtube.com/', '') }}
            </a>
          </div>
          <div v-if="p.socialProfiles.blogUrl" class="list-group-item p-1 border-0">
            <a target="_blank" :href="p.socialProfiles.blogUrl">
              <font-awesome-icon :icon="['fab', 'blogger']"></font-awesome-icon>
              {{ p.socialProfiles.blogUrl.replace('https://www.', '') }}
            </a>
          </div>
        </div>

        <div class="card-footer d-flex justify-content-center justify-content-lg-end">
          <div class="btn-group btn-group-sm">
            <b-button v-if="!p.approved" @click="approve(p, true)">
              <font-awesome-icon icon="check"></font-awesome-icon>
              <div class="d-none d-md-inline ml-md-1">Approve</div>
            </b-button>
            <b-button v-if="p.approved" @click="approve(p, false)" variant="danger">
              <font-awesome-icon icon="times"></font-awesome-icon>
              <div class="d-none d-md-inline ml-md-1">Deny</div>
            </b-button>
            <b-button @click="editPartner(p)" variant="primary">
              <font-awesome-icon icon="pencil"></font-awesome-icon>
              <div class="d-none d-md-inline ml-md-1">Edit</div>
            </b-button>
            <b-button @click="editBank(p)" variant="success">
              <font-awesome-icon :icon="['far','money-check-alt']"></font-awesome-icon>
              <div class="d-none d-md-inline ml-md-1">Bank</div>
            </b-button>
            <b-button @click="editAddr(p)" variant="info">
              <font-awesome-icon icon="building"></font-awesome-icon>
              <div class="d-none d-md-inline ml-md-1">Address</div>
            </b-button>
            <b-button @click="editCode(p)" variant="danger">
              <font-awesome-icon icon="badge-percent"></font-awesome-icon>
              <div class="d-none d-md-inline ml-md-1"> Discount</div>
            </b-button>
            <b-button @click="sendMessage(p)" variant="success">
              <font-awesome-icon icon="envelope"></font-awesome-icon>
              <div class="d-none d-md-inline ml-md-1"> Message</div>
            </b-button>
            <b-button @click="partnerUploads(p)" variant="warning">
              <font-awesome-icon icon="upload"></font-awesome-icon>
              Uploads
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="edit-partner" @ok="savePartner" title="Edit Partner">
      <b-form-group label="Name">
        <b-form-input v-model="partner.name"></b-form-input>
      </b-form-group>
      <b-form-group label="Mobile">
        <b-form-input v-model="partner.mobile"></b-form-input>
      </b-form-group>
      <b-form-group label="EMail">
        <b-form-input v-model="partner.email"></b-form-input>
      </b-form-group>
      <b-form-group label="Notes">
        <b-form-textarea v-model="partner.notes"></b-form-textarea>
      </b-form-group>
      <b-form-group label="Brand">
        <b-form-select :options="$store.state.brands" value-field="id" text-field="name" v-model="partner.brandId"></b-form-select>
      </b-form-group>
      <b-form-group label="Payment for First Post">
        <b-form-input v-model="partner.initPayment" type="number"></b-form-input>
      </b-form-group>
      <b-form-group label="Next Step Payment (for additional followers)">
        <b-form-input v-model="partner.nextStep" type="number"></b-form-input>
      </b-form-group>
      <b-form-group label="Instagram">
        <b-form-input v-model="partner.socialProfiles.instagram"></b-form-input>
      </b-form-group>
      <b-form-group label="Facebook">
        <b-form-input v-model="partner.socialProfiles.facebook"></b-form-input>
      </b-form-group>
      <b-form-group label="Youtube">
        <b-form-input v-model="partner.socialProfiles.youtube"></b-form-input>
      </b-form-group>
      <b-form-group label="Blog Url">
        <b-form-input v-model="partner.socialProfiles.blogUrl"></b-form-input>
      </b-form-group>
    </b-modal>
    <b-modal id="edit-p-addr" @ok="saveAddr" :title="'Save Address for '+partner.name">

      <b-form-group label="Addr Line 1">
        <b-form-input v-model="a.addr1"></b-form-input>
      </b-form-group>
      <b-form-group label="Addr Line 2">
        <b-form-input v-model="a.addr2"></b-form-input>
      </b-form-group>

      <b-form-group label="City">
        <b-form-input v-model="a.city"></b-form-input>
      </b-form-group>

      <b-form-group label="State">
        <b-form-input v-model="a.state"></b-form-input>
      </b-form-group>

      <b-form-group label="Pincode">
        <b-form-input v-model="a.pincode"></b-form-input>
      </b-form-group>


    </b-modal>
    <b-modal id="edit-p-bank" @ok="saveBank" :title="'Save Bank Details for '+partner.name">

      <b-form-group label="Bank Name">
        <b-form-input v-model="b.name"></b-form-input>
      </b-form-group>
      <b-form-group label="Acc No">
        <b-form-input v-model="b.acc"></b-form-input>
      </b-form-group>

      <b-form-group label="IFSC">
        <b-form-input v-model="b.ifsc"></b-form-input>
      </b-form-group>

    </b-modal>
    <b-modal id="edit-p-code" hide-footer :title="'Save Discount for '+partner.name">

      <b-form-group label="Discount Code">
        <b-form-input v-model="c.code" :disabled="c.id"></b-form-input>
      </b-form-group>
      <b-form-group label="Valid Till">
        <b-datepicker v-model="c.validTill"></b-datepicker>
      </b-form-group>


      <div v-if="codes.length > 0">
        <h6 class="mt-5">Existing Codes</h6>
        <table class="table">
          <thead>
          <tr>
            <th>Code</th>
            <th>ValidTill</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="dc of codes" :key="dc.id">
            <td>{{ dc.code }}</td>
            <td>{{ dc.validTill }}</td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="modal-footer">
        <div class="d-flex flex-fill justify-content-between">

          <div class="d-flex flex-fill justify-content-end">
            <b-button @click="createCode()" v-if="codes.length > 0" variant="warning">
              Create New Code
            </b-button>
            <b-button @click="closeCode()" variant="secondary" class="ml-2">
              Close
            </b-button>
            <b-button @click="saveCode()" variant="primary" class="ml-2">
              Save Code
            </b-button>
          </div>

        </div>

      </div>

    </b-modal>
    <b-modal id="msg-partners" hide-footer title="Send Message to Partners">
      <b-form-group label="Message">
        <b-form-textarea v-model="mesg"></b-form-textarea>
      </b-form-group>

      <b-form-group label="Brand">
        <b-form-select :options="$store.state.brands" value-field="id" text-field="name" v-model="brandId"></b-form-select>
      </b-form-group>
      <div class="list-group">
        <div class="list-group-item d-flex justify-content-end">
          <div class="btn-group btn-group-sm">
            <b-button @click="all('KIT')" variant="primary">
              <font-awesome-icon icon="check"></font-awesome-icon>
              Select Kit sent
            </b-button>
            <b-button @click="all('NOKIT')" variant="warning">
              <font-awesome-icon icon="check"></font-awesome-icon>
              Select kit not sent
            </b-button>
            <b-button @click="all('ALL')" variant="success">
              <font-awesome-icon icon="check"></font-awesome-icon>
              Select All
            </b-button>
            <b-button @click="all('')" variant="danger">
              <font-awesome-icon icon="times"></font-awesome-icon>
              Deselect All
            </b-button>
          </div>
        </div>
        <div class="list-group-item d-flex justify-content-between" v-for="p of partnerSend" :key="p.id">
          <span>{{ p.name }} ({{ p.mobile }})
            (sent kit? <span v-if="p.sentKit" class="text-success"><font-awesome-icon icon="check"></font-awesome-icon></span>
              <span v-else class="text-danger"><font-awesome-icon icon="times"></font-awesome-icon></span>)
          </span>
          <b-form-checkbox
              :id="`checkbox-${p.id}`"
              v-model="p.send"
              :name="`checkbox-${p.id}`"
              :value="true"
              switch
              :unchecked-value="false">
            Send
          </b-form-checkbox>

        </div>
      </div>

      <div class="d-flex justify-content-end">
        <b-button @click="sendMessageToPartners">
          Send Message
        </b-button>
      </div>
    </b-modal>
    <b-modal id="uploads" :title="'Uploads by'+partner.name">

      <table class="table">
        <thead>
        <tr>
          <th>Sl.No</th>
          <th>Name</th>
          <th>Date</th>
          <th>Image/Video</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(u, idx) of uploads" :key="u.id">
          <td>{{idx + 1}}</td>
          <td>{{u.name}}</td>
          <td>{{u.createdDateStr}}</td>
          <td>
            <a :href="u.url" target="_blank">
              <img :srcset="u.srcSet" sizes="130px" />
            </a>
          </td>
        </tr>
        </tbody>
      </table>
    </b-modal>
  </section>
</template>

<script>
import axios from 'axios'
import Rupee from '@/components/rupee'
import DisplayBrand from "@/components/display-brand";

export default {
  name: "Partner",
  components: {DisplayBrand, Rupee},
  data() {
    return {
      mesg: '',
      brandId: '',
      partners: [],
      uploads: [],
      partnerSend: [],
      codes: [],
      newPartner: {
        name: '',
        mobile: '',
        email: '',
        emailValidated: false,
        discountCode: '',
        initPayment: 400,
        nextStep: 200,
        socialProfiles: {
          instagram: '',
          facebook: '',
          youtube: '',
          blogUrl: '',
          others: []
        },
      },
      partner: {
        socialProfiles: {}
      },
      pbanks: [],
      paddr: [],
      a: {
        addr1: '',
        addr2: '',
        city: '',
        state: '',
        pincode: ''
      },
      b: {
        name: '',
        acc: '',
        ifsc: ''
      },
      c: {
        code: '',
        validTill: '',
        id: null
      }
    }
  },
  computed: {},
  methods: {
    async all(t) {
      console.log('all', t)
      this.partnerSend.forEach(p => {
        if (t === 'KIT') {
          this.$set(p, 'send', p.sentKit)
        } else if (t === 'NOKIT') {
          this.$set(p, 'send', !p.sentKit)
        } else if (t === 'ALL') {
          this.$set(p, 'send', true)
        } else {
          this.$set(p, 'send', false)
        }

      })
    },
    async sendMessageToPartners() {
      this.$bvModal.hide('msg-partners')
      let fd = new FormData()
      fd.append('mesg', this.mesg)


      this.partnerSend
          .filter(s => s.send === 'true' || s.send === true)
          .forEach(d => {
            fd.append('id', d.id)
          })

      await axios({
        method: 'post',
        url: '/api/admin/partner/broadcast?brand_id='+this.brandId,
        data: fd,
        headers: {'Content-Type': 'multipart/form-data'}
      })
    },
    async message() {
      this.$bvModal.show('msg-partners')
    },
    async loadPartners() {
      let r = await axios.get("/api/admin/partner")
      this.partners = r.data
      this.partnerSend = r.data.map(c => {
        return {
          id: c.id,
          name: c.name,
          mobile: c.mobile,
          sentKit: c.sentKit,
          send: true
        }
      })
    },
    editPartner(p) {
      this.partner = Object.assign({}, p || this.newPartner)
      this.$bvModal.show('edit-partner')
    },
    async savePartner() {
      await axios.post("/api/admin/partner", this.partner)
      this.$bvModal.hide('edit-partner')
      await this.loadPartners()
    },
    async approve(p, a) {
      await axios.patch(`/api/admin/partner/${p.id}?approve=${a}`)
      await this.loadPartners()
    },
    async editBank(p) {
      this.partner = p
      let banks = (await axios.get(`/api/admin/partner/${p.id}/bank`)).data
      if (banks.length === 0) {
        this.b = {
          acc: '',
          name: '',
          ifsc: ''
        }
      } else {
        this.b = banks[0]
      }
      this.$bvModal.show('edit-p-bank')
    },
    async createCode() {
      this.c = {
        code: '',
        validTill: '',
        id: null
      }
    },
    async editCode(p) {
      this.partner = p
      let banks = (await axios.get(`/api/admin/partner/${p.id}/discount`)).data
      this.codes = banks
      if (banks.length === 0) {
        this.c = {
          code: '',
          validTill: '',
          id: null
        }
      } else {
        this.c = banks[0]
      }
      this.$bvModal.show('edit-p-code')
    },
    async partnerUploads(p) {
      this.partner = p
      this.uploads = (await axios.get(`/api/admin/partner/${p.id}/uploads`)).data
      this.$bvModal.show('uploads')
    },
    async editAddr(p) {
      this.partner = p

      let banks = (await axios.get(`/api/admin/partner/${p.id}/address`)).data
      if (banks.length === 0) {
        this.a = {
          addr1: '',
          addr2: '',
          city: '',
          state: '',
          pincode: '',
        }
      } else {
        this.a = banks[0]
      }
      this.$bvModal.show('edit-p-addr')
    },
    async saveBank() {
      let p = this.partner
      await axios.post(`/api/admin/partner/${p.id}/bank`, this.b)
      this.$bvModal.hide('edit-p-bank')
    },
    async saveAddr() {
      let p = this.partner
      await axios.post(`/api/admin/partner/${p.id}/address`, this.a)
      this.$bvModal.hide('edit-p-bank')
    },
    async saveCode() {
      let p = this.partner
      await axios.post(`/api/admin/partner/${p.id}/discount`, this.c)
      await this.closeCode()
    },
    async closeCode() {
      this.$bvModal.hide('edit-p-code')
    },
    async sendMessage(p) {
      await this.$store.dispatch('sendMessage', {id: p.mobile, mobile: p.mobile, name: p.name})
    },
  },
  async created() {
    await this.loadPartners()
  },
}
</script>

<style scoped>

</style>