<template>
  <section class=" p-5">
    <h1>Tools</h1>
    <h2>Download Images</h2>

    <div class="d-flex">
      <div class="d-flex flex-column">
        <b-form-group label="Products"
                      style="width:300px"
                      class="mr-3">
          <v-select v-model="productId"
                    class="style-chooser"
                    :options="products"
                    label="name"></v-select>
        </b-form-group>


        <div>

          <b-button @click="addToQueue" class="mt-3">
            <font-awesome-icon :icon="['far','plus']"></font-awesome-icon>
            Add To Queue
          </b-button>
        </div>
      </div>

      <div class="d-flex flex-fill w-100">
        <form method="post" :action="downloadUrl" v-if="selected.length > 0">
          <div class="list-group mt-3">
            <div class="list-group-item" v-for="(sp, idx) in selected" :key="sp.id">
              <input type="hidden" name="id" :value="sp.id"/>
              <div class="d-flex justify-content-between">
                {{ sp.name }}

                <b-button variant="outline-danger" @click="selected.splice(idx, 1)" class="ml-5">
                  <font-awesome-icon :icon="['far','times']"></font-awesome-icon>
                </b-button>
              </div>
            </div>
            <div class="list-group-item">
              <button  type="submit" class="btn btn-primary">
                <font-awesome-icon :icon="['far','download']"></font-awesome-icon>
                Download
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>


  </section>
</template>
<script>
import axios from "axios";

export default {
  name: "tools",
  data() {
    return {
      productId: null,
      products: [],
      selected: []
    }
  },
  computed: {
    selectedProducts() {
      return this.selected.map(p => p.id)
    },
    downloadUrl(){
      return process.env.VUE_APP_URL + '/download-images'
    }
  },
  methods: {
    addToQueue() {
      this.selected.push(this.productId)
      this.productId = null
    },
  },
  async created() {
    let pds = await axios.get(`/api/admin/products`)
    this.products = pds.data
  },
}
</script>