<template>
  <section>
    <div class="d-flex flex-row justify-content-between align-content-center align-items-center">
      <h1>Blogs</h1>
      <b-button-group class="">

        <b-button @click="addBlog">
          <font-awesome-icon icon="plus"></font-awesome-icon>
          New Blog
        </b-button>

        <b-button @click="loadBlogs" variant="primary">
          <font-awesome-icon icon="sync"></font-awesome-icon>
          Refresh
        </b-button>
      </b-button-group>
    </div>
    <hr class="my-3">
    <div class="d-flex flex-column flex-md-row flex-wrap mt-3">
      <div class="card mr-md-2 mb-2" v-for="b of blogs" :key="b.id">
        <div class="card-header d-flex justify-content-between">
          <span>{{ b.title }}</span>
          <span class="ml-3"><b-badge>{{ b.nodeType }}</b-badge></span>
        </div>
        <div class="card-body" style="max-width: 30em">
          {{ b.snippet }}
        </div>
        <div class="card-footer d-flex justify-content-between">
          <display-brand v-if="b.brand" :id="b.brand.id" :border="false"></display-brand>
          <b-button-group>
            <b-button @click="windowOpen('https://'+b.brand.url+'/blog/'+b.slug, '_blank')">
              <font-awesome-icon icon="eye"></font-awesome-icon>
            </b-button>
            <b-button @click="editBlog(b)">
              <font-awesome-icon icon="pencil"></font-awesome-icon>
            </b-button>
          </b-button-group>
        </div>
      </div>
    </div>
    <b-modal id="edit-blog" title="Edit Blog" hide-footer size="xl">

      <form @submit.prevent="saveBlog">

        <b-form-group label="Title">
          <b-form-input v-model="blog.title" @change="updateSlug"></b-form-input>
        </b-form-group>
        <b-form-group label="Slug">
          <b-form-input v-model="blog.slug" :disabled="blog.id !== null"></b-form-input>
        </b-form-group>

        <b-form-checkbox v-model="html" switch>HTML Editor</b-form-checkbox>

        <div class="mt-3 d-flex flex-column">
          <h5 class="mb-3">Content</h5>

          <b-form-textarea v-model="blog.body" v-if="html" rows="20"></b-form-textarea>
          <vue-editor
              v-else
              @blur="updateSnippet"
              v-model="blog.body"></vue-editor>

        </div>


        <file-upload v-model="img" :resize="true" @input="addNewImage"></file-upload>

        <b-form-group label="Snippet">
          <b-form-textarea v-model="blog.snippet"></b-form-textarea>
        </b-form-group>

        <b-form-group label="Tags">
          <b-form-tags input-id="tags-basic" v-model="blog.tags" class="mb-2"></b-form-tags>
        </b-form-group>

        <b-form-group label="Products">
          <v-select :options="$store.state.products" :reduce="p => p.id" label="name"
                    multiple
                    v-model="blog.products"></v-select>
        </b-form-group>


        <b-form-group label="Brand">
          <b-form-select v-model="blog.brand.id" :options="$store.state.brands"
                         value-field="id"
                         text-field="name"></b-form-select>
        </b-form-group>
        <b-button type="submit" :disabled="!blog.brand.id">
          <font-awesome-icon icon="save"></font-awesome-icon>
          Save
        </b-button>
      </form>

    </b-modal>
  </section>
</template>
<script>
import {VueEditor} from "vue2-editor";
import axios from "axios";
import FileUpload from '@/components/file-upload'
import DisplayBrand from "@/components/display-brand";

export default {
  name: "BlogContent",
  components: {DisplayBrand, VueEditor, FileUpload},
  data: () => ({
    blogs: [],
    blog: {products: [], tags: [], brand: {id: null}},
    img: '',
    html: false,
  }),
  async created() {
    await this.loadBlogs()
  },
  methods: {
    addNewImage(a) {
      setTimeout(() => {
        this.blog.mainImg = a
      }, 500)
    },
    async loadBlogs() {
      this.blogs = (await axios.get("/api/admin/blog")).data
    },
    editBlog(b) {
      this.blog = Object.assign({}, b)
      this.$bvModal.show('edit-blog')
    },
    async windowOpen(u, t) {
      window.open(u, t)
    },
    async saveBlog() {
      await axios.post("/api/admin/blog", this.blog)
      await this.loadBlogs()
      this.$bvModal.hide('edit-blog')
    },
    addBlog() {
      this.editBlog(this.newBlog())
    },
    async updateSnippet() {
      if (this.blog.snippet.length === 0) {
        let div = document.createElement("div")
        div.innerHTML = this.blog.body
        this.blog.snippet = (div.textContent || div.innerText).substr(0, 100)
      }
    },
    async updateSlug() {
      if (this.blog.slug.length === 0) {
        this.blog.slug = this.blog.title.toLowerCase().replace(/[^0-9a-zA-Z]/gi, '-')
      }
    },
    newBlog() {
      return {
        id: null,
        nodeType: 'BLOG',
        slug: '',
        snippet: '',
        description: '',
        body: '',
        mainImg: '',
        author: 'The Millet Store',
        tags: [],
        brand: {}
      }
    }
  }
}
</script>