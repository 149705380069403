<template>
  <section>
    <div class="d-flex flex-column flex-md-row">
      <div class="d-flex flex-column  mr-3 p-3 w-100">
        <h2>Print Label</h2>

        <div class="d-flex flex-column flex-md-row w-100">

          <b-form>
            <b-form-group label="What" style="width: 300px">
              <b-form-select v-model="what" :options="whatOptions"
                             :disabled="prints.length > 0"
              ></b-form-select>
            </b-form-group>


            <b-form-group label="Products" style="width:300px" class="mr-3">
              <v-select v-model="productId"
                        class="style-chooser"
                        @input="loadProduct"
                        :options="products"
                        :reduce="p => p.id"
                        label="name"></v-select>
            </b-form-group>

            <b-form-group label="Variant" style="width: 300px">
              <b-form-select v-model="variantId" :options="variants"
                             value-field="id" text-field="name"
              ></b-form-select>
            </b-form-group>

            <b-form-group label="Date Of Pkg" v-if="what === 'STORE_LABEL'">
              <b-form-input v-model="pkg"></b-form-input>
            </b-form-group>

            <b-form-group label="Best Before" v-if="what === 'STORE_LABEL'">
              <b-form-input v-model="bb"></b-form-input>
            </b-form-group>

            <b-form-group label="Max Labels">
              <b-form-input :value="labels" readonly type="number"></b-form-input>
            </b-form-group>

            <b-form-group label="Label Count">
              <b-form-input v-model="qty" :max="labels - prints.length" type="number"></b-form-input>
            </b-form-group>


            <b-button-group class="mt-4" size="lg">

              <b-button :disabled="disableAddToProduct" variant="primary"
                        @click="addToList">
                <font-awesome-icon icon="plus"></font-awesome-icon>
                Add To Queue
              </b-button>
            </b-button-group>

          </b-form>

          <div class="flex flex-column px-2 px-md-4">

            <!-- -->
            <div class="list-group w-100">
              <div class="list-group-item">
                <strong>To Print</strong>
              </div>
              <div class="list-group-item d-flex justify-content-between" v-for="(px, idx) of prints" :key="idx">
                <span class=""><strong>{{ px.who }}</strong> </span>
                <span class="ml-3">{{ px.qty }}</span>
              </div>
              <div class="list-group-item d-flex justify-content-between">
                <span class=""><strong>Total</strong> </span>
                <span class="ml-3">{{ printCount }} / {{ labels }}</span>
              </div>

              <div class="list-group-item d-flex justify-content-between">

                <b-button variant="danger" @click="prints = []">
                  <font-awesome-icon icon="times"></font-awesome-icon>
                  Clear
                </b-button>
                <form method="post" :action="postUrl" target="_blank">
                  <input type="hidden" :value="$store.state.authToken" name="x-auth-token" />
                  <input type="hidden" name="print-count" :value="prints.length">
                  <div v-for="(px, idx) of prints" :key="idx">
                    <input type="hidden" :name="'what['+idx+']'" :value="px.what" />
                    <input type="hidden" :name="'productId['+idx+']'" :value="px.productId" />
                    <input type="hidden" :name="'variantId['+idx+']'" :value="px.variantId" />
                    <input type="hidden" :name="'qty['+idx+']'" :value="px.qty" />
                    <input type="hidden" :name="'bb['+idx+']'" :value="px.bb" />
                    <input type="hidden" :name="'pkg['+idx+']'" :value="px.pkg" />
                  </div>
                  <b-button v-if="canPrint" variant="primary" :disabled="isInvalid" type="submit">
                    <font-awesome-icon icon="print"></font-awesome-icon>
                    Print
                  </b-button>
                </form>

              </div>
            </div>

          </div>
        </div>

      </div>

    </div>

  </section>
</template>
<script>
import {AddProduct} from '@/util'
import axios from 'axios'
import {format} from "date-fns";

export default {
  name: 'IngPrint',
  components: {},
  data() {
    return {
      whatOptions: ["INGREDIENT", "FRONT", "BACK", "STORE_LABEL"],
      productId: null,
      variantId: null,
      what: 'INGREDIENT',
      product: AddProduct(),
      products: [],
      pkg: format(new Date(), "dd/MM/yyyy"),
      bb: '3 Months',
      lblCount: {
        'STORE_LABEL': 21
      },
      prints: [],
      qty: 0,

    }
  },
  computed: {
    canPrint() {
      return this.printCount === this.labels
    },
    labels() {
      return this.lblCount[this.what] || 21
    },
    variant() {
      if (!this.variantId) return {price: 0};
      return this.variants.filter(v => v.id === this.variantId)[0]
    },
    isInvalid() {
      return !(this.productId && this.variantId)
    },
    variants() {
      if (!this.productId) return []
      return this.products.filter(p => p.id === this.productId)[0].variants
    },
    printCount() {
      return this.prints.map(l => l.qty).reduce((a, b) => a + b, 0)
    },
    disableAddToProduct() {
      //!this.productId || !this.variantId || prints.length >= labels || qty < 1 || qty > (labels - prints.length)
      console.log('disableAddToProduct', this.productId, this.variantId, this.qty)
      if (!this.productId) return true
      if (!this.variantId) return true
      if (!this.qty) return true
      if (this.qty === 0) return true

      console.log('disableAddToProduct', this.productId, this.variantId, this.qty, 'max?', this.printCount >= this.labels, 'pend', this.labels - this.printCount)
      if (this.printCount >= this.labels) return true
      return this.qty > (this.labels - this.printCount);


    },
    postUrl() {
      return `${process.env.VUE_APP_URL}/api/admin/labels/action`
    }
  },
  methods: {
    async addToList() {
      let p = this.$store.state.products.filter(p => p.id === this.productId)
      let v = p[0].variants.filter(v => v.id === this.variantId)
      this.prints.push({
        who: p[0].name + '  ' + v[0].name,
        qty: +this.qty,
        what: this.what,
        bb: this.bb,
        pkg: this.pkg,
        productId: this.productId,
        variantId: this.variantId,
      })
    },
    async loadProduct() {
      let p = await axios.get(`/api/admin/products/${this.productId}`)
      this.product = p.data
      this.variantId = this.product.variants[0].id
    },
  },
  async created() {
    let pds = await axios.get(`/api/admin/products`)
    this.products = pds.data

  },
}
</script>
<style scoped>

</style>