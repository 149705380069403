<template>
  <section class="p-2">

    <div class="d-flex justify-content-between">
      <h2>Discount Codes</h2>

      <b-button-group>

        <b-button variant="primary" @click="getCodes()">
          Reload
        </b-button>

        <b-button variant="success" @click="editCode({})">
          Add
        </b-button>
      </b-button-group>
    </div>

    <div class="d-flex flex-column flex-md-row flex-wrap">

      <div class="card  mb-3 mr-md-3" v-for="c of codes" :key="c.id" style="max-width: 400px">
        <div class="card-header">{{ c.code }}</div>

        <div class="card-body">
          <div class="list-group">
            <div class="list-group-item">
              ValidTill:
              {{ c.validTill }}
            </div>
            <div class="list-group-item">Usage Per Customer: {{ c.usagePerCustomer }}</div>
            <div class="list-group-item">Site Usage: {{ c.usagePerSite }}</div>
            <div class="list-group-item">
              Discount:
              <span v-if="c.discountType === 'PERCENT'">{{ c.discountVal }} %</span>
              <span v-else><rupee>{{ c.discountVal }}</rupee></span>
            </div>
            <div class="list-group-item">Used Count: {{ c.usageCount }}</div>
            <div class="list-group-item">
              Min Order:
              <rupee>{{ c.minOrder }}</rupee>
            </div>
            <div class="list-group-item">
              Max Discount:
              <rupee>{{ c.maxDiscount }}</rupee>
            </div>
            <div class="list-group-item" v-bind:class="{'list-group-item-success': c.automatic}">
              Automatic
              <font-awesome-icon icon="check" v-if="c.automatic"></font-awesome-icon>
              <font-awesome-icon icon="times" v-else></font-awesome-icon>
            </div>
            <div class="list-group-item" v-if="c.onCollections && c.onCollections.length > 0">
              <ul class="list-unstyled">
                <li>
                  <font-awesome-icon icon="barcode"></font-awesome-icon>
                  Collections
                </li>
                <li class="ml-2" v-for="cc of c.onCollections" :key="cc.id">{{ cc.name }}</li>
              </ul>
            </div>
            <div class="list-group-item" v-if="c.onProducts && c.onProducts.length > 0">
              <ul class="list-unstyled">
                <li>
                  <font-awesome-icon icon="barcode-alt"></font-awesome-icon>
                  Products
                </li>
                <li class="ml-2" v-for="cc of c.onProducts" :key="cc.id">{{ cc.name }}</li>
              </ul>
            </div>
            <div class="list-group-item" v-if="c.brand">
              <display-brand :id="c.brand.id" :border="false"></display-brand>
            </div>

          </div>
        </div>
        <div class="card-footer d-flex justify-content-end">
          <b-btn-group>
            <b-button variant="success" @click="editCode(c)">
              Edit
            </b-button>
            <b-button variant="danger" @click="deleteCode(c)">
              Delete
            </b-button>
          </b-btn-group>
        </div>
      </div>
    </div>
    <b-modal id="edit-code" title="Discount Code Edit" @ok="saveCode">
      <b-form-group label="Code">
        <b-form-input v-model="code.code"></b-form-input>
      </b-form-group>

      <b-form-group label="Discount Type">
        <b-form-select :options="discountTypes" v-model="code.discountType"></b-form-select>
      </b-form-group>

      <b-form-group label="Use Per Customer">
        <b-form-input v-model="code.usagePerCustomer"></b-form-input>
      </b-form-group>
      <b-form-group label="Total Allowed Usage">
        <b-form-input v-model="code.usagePerSite"></b-form-input>
      </b-form-group>
      <b-form-group label="Min Order Value">
        <b-form-input v-model="code.minOrder"></b-form-input>
      </b-form-group>
      <b-form-group label="Discount Value">
        <b-form-input v-model="code.discountVal" @change="updateMaxDisc"></b-form-input>
      </b-form-group>

      <b-form-group label="Max Discount Value">
        <b-form-input v-model="code.maxDiscount" :disabled="code.discountType === 'FIXED'"></b-form-input>
      </b-form-group>
      <b-form-group label="Valid Till">
        <b-datepicker v-model="code.validTill"></b-datepicker>
      </b-form-group>
      <b-form-group label="">
        <b-form-checkbox v-model="code.automatic">Apply Automatically?</b-form-checkbox>
      </b-form-group>
      <b-form-group label="Only on Categories">
        <v-select v-model="code.onCollections"
                  multiple
                  :options="$store.state.categories"
                  label="name" :reduce="p => p.id"></v-select>
      </b-form-group>
      <b-form-group label="Only on Products">
        <v-select v-model="code.onProducts"
                  multiple
                  :options="$store.state.products" label="name" :reduce="p => p.id"></v-select>
      </b-form-group>
      <b-form-group label="Brand">
        <b-form-select v-model="code.brand.id" :options="$store.state.brands" value-field="id"
                       text-field="name"></b-form-select>
      </b-form-group>
    </b-modal>
  </section>
</template>
<script>
import axios from "axios";
import rupee from '@/components/rupee'
import DisplayBrand from "@/components/display-brand.vue";

export default {
  name: "discounts",
  components: {DisplayBrand, rupee},
  data() {
    return {
      codes: [],
      discountTypes: ['FIXED', 'PERCENT'],
      newCode: {
        code: '',
        usagePerCustomer: 1,
        usagePerSite: 1000,
        minOrder: 500,
        maxDiscount: 1000,
        discountVal: 0,
        discountType: 'PERCENT',
        automatic: false,
        onCollections: [],
        onProducts: [],
        brand: {
          id: null
        }
      },
      code: {
        brand: {
          id: null
        }
      }
    }
  },
  computed: {},
  methods: {
    async updateMaxDisc() {
      if (this.code.discountType === 'FIXED') {
        this.code.maxDiscount = this.code.discountVal
      }
    },
    async editCode(c) {
      let source = Object.assign({}, (c || this.newCode));
      source.onCollections = (source.onCollections || []).map(cx => cx.id)
      source.onProducts = (source.onProducts || []).map(cx => cx.id)
      let brand = source.brand || {id: null}
      this.code = Object.assign({}, source, {brand})
      this.$bvModal.show('edit-code')
    },
    async deleteCode(c) {
      await axios.delete(`/api/admin/discount/${c.id}`)
      await this.getCodes()
    },
    async saveCode() {
      await axios.post('/api/admin/discount', this.code)
      await this.getCodes()
      this.$bvModal.hide('edit-code')
    },
    async getCodes() {
      let pds = await axios.get(`/api/admin/discount`)
      this.codes = pds.data.map(cc => {
        cc.onCollections = (cc.onCollections || []).map(cn => {
          return this.$store.state.categories.filter(cx => cx.id === cn)[0]
        })
        cc.onProducts = (cc.onProducts || []).map(cn => {
          return this.$store.state.products.filter(cx => cx.id === cn)[0]
        })
        console.info(cc)
        return cc
      })
    }
  },
  async created() {
    await this.getCodes()
  },
}
</script>