<template>
  <div class="d-flex flex-column">
    <h2>Stock Entry</h2>

    <div class="d-flex flex-column flex-md-row align-content-md-center align-items-md-center mb-3">
      <!-- filter -->
      <!-- product -->

      <b-form-group label="Product" class="mr-3 product-select" style="width: 300px">
        <v-select :options="$store.state.products"
                  :reduce="p => p.id"
                  v-model="s.product" label="name"></v-select>
      </b-form-group>

      <b-form-group label="From Date" class="mr-3">
        <b-form-datepicker v-model="s.fromDate"
                           class="mr-0 mr-md-3 w-100 w-md-50 mt-3"
                           value-as-date></b-form-datepicker>
      </b-form-group>
      <b-form-group label="To Date" class="mr-3">

        <b-form-datepicker v-model="s.toDate"
                           class="mr-0 mr-md-3 w-100 w-md-50 mt-3"
                           value-as-date></b-form-datepicker>
      </b-form-group>

      <div class="flex-grow-1 text-right">

        <b-button-group class="mt-4" size="lg">
          <b-button variant="primary" @click="loadStock">
            <font-awesome-icon icon="sync"></font-awesome-icon>
            <span class="d-none d-md-inline ml-2">Refresh</span>
          </b-button>
          <b-button variant="warning" @click="loadStock(true)">
            <font-awesome-icon icon="times"></font-awesome-icon>
            <span class="d-none d-md-inline ml-2">Clear</span>
          </b-button>
          <b-button variant="success" @click="newStock">
            <font-awesome-icon icon="plus"></font-awesome-icon>
            <span class="d-none d-md-inline ml-2">New</span>
          </b-button>
        </b-button-group>
      </div>
    </div>


    <div class="row row-cols-1 row-cols-lg-6 d-none d-md-flex">
      <div class="col p-2 border">At</div>
      <div class="col p-2 border">Product & Variant</div>
      <div class="col p-2 border">By</div>
      <div class="col p-2 border">For</div>
      <div class="col p-2 border">Qty</div>
      <div class="col p-2 border">&nbsp;</div>
    </div>


    <div class="row row-cols-1 row-cols-lg-6" v-for="s of stocks" :key="s.id"
         v-bind:class="{'bg-warning': s.qty < 0}">
      <div class="col p-2 border"><span class="d-inline d-md-none">At :</span> {{ s.at }}</div>
      <div class="col p-2 border"><span class="d-inline d-md-none">Product :</span> {{ s.product.name }},
        {{ s.variant.name }}
      </div>
      <div class="col p-2 border"><span class="d-inline d-md-none">By :</span> {{ s.by }}</div>
      <div class="col p-2 border"><span class="d-inline d-md-none">For :</span> {{ s.reason }}</div>
      <div class="col p-2 border"><span class="d-inline d-md-none">Qty :</span> {{ s.qty }}</div>
      <div class="col p-2 border">&nbsp;</div>

    </div>


    <b-modal id="stock-entry" title="Stock Entry" @ok="saveStock" size="xl">
      <div class="d-flex justify-content-between mb-3">
        <h2>New Stock Entry</h2>
        <b-button @click="stock.push(newEntry())">
          <font-awesome-icon icon="plus"></font-awesome-icon>
        </b-button>
      </div>

      <div class="row row-cols-1 row-cols-lg-6" v-for="(s, ix) of stock" :key="ix">
        <div class="col">
          <b-form-group label="Store">
            <v-select :options="$store.state.stores"
                      :reduce="p => p.id"
                      v-model="s.store.id" label="name"></v-select>
          </b-form-group>
        </div>
        <div class="col">
          <b-form-group label="Product">
            <v-select :options="$store.state.products"
                      :reduce="p => p.id"
                      v-model="s.product.id" label="name"></v-select>
          </b-form-group>
        </div>
        <div class="col">
          <b-form-group label="Variant">
            <v-select :options="variants(s)"
                      :reduce="p => p.id"
                      v-model="s.variant.id" label="name"></v-select>
          </b-form-group>
        </div>
        <div class="col">
          <b-form-group label="By">
            <b-form-input v-model="s.by"></b-form-input>
          </b-form-group>
        </div>
        <div class="col">
          <b-form-group label="For">
            <b-form-input v-model="s.reason"></b-form-input>
          </b-form-group>
        </div>
        <div class="col">
          <b-form-group label="Qty">
            <b-input-group>
              <b-form-input v-model="s.qty" type="number"></b-form-input>
              <b-input-group-append>
                <b-button variant="danger" @click="stock.splice(ix, 1)">
                  <font-awesome-icon icon="times"></font-awesome-icon>
                </b-button>

              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </div>
    </b-modal>

  </div>
</template>
<script>
import {addDays} from "date-fns";
import axios from "axios";

export default {
  name: 'AdminStock',
  data: () => ({
    s: {
      fromDate: addDays(new Date(), -7),
      toDate: new Date(),
      product: null,
    },
    stocks: [],
    stock: [],
    variantList: {}
  }),
  computed: {},
  async created() {
    await this.loadStock()
  },
  methods: {
    variants(li) {
      if (li.product && li.product.id) {

        let products = this.$store.state.products
            .filter(p => p.id === li.product.id)

        if (products.length === 0) return []
        return products[0].variants
      }
      return []
    },
    newEntry() {
      let store = {id: null}, by = '', reason = ''
      if (this.stock.length > 0) {
        store.id = this.stock[0].store.id
        by = this.stock[0].by
        reason = this.stock[0].reason
      }
      return {
        product: {id: null},
        variant: {id: null},
        store: store,
        at: new Date(),
        by: by,
        reason: reason,
        qty: 0
      }
    },
    defSearch() {
      return {
        fromDate: addDays(new Date(), -7),
        toDate: new Date(),
        product: null,
      }
    },
    async loadStock(y) {
      if (y === true) {
        this.s = this.defSearch()
      }
      let r = await axios.post("/api/admin/stock/search", this.s)
      this.stocks = r.data
    },
    async newStock() {
      this.stock = [this.newEntry()]
      this.$bvModal.show('stock-entry')
    },
    async saveStock() {
      await Promise.all(
          this.stock.map(async (s) => {
            await axios.post("/api/admin/stock", s)
          })
      )
      await this.loadStock()
    }
  }
}
</script>
<style scoped>
.product-select .v-select {
  margin-top: 15px;
}
</style>