<template>
  <section class="p-2">

    <div class="d-flex justify-content-between mb-3">
      <h2>Brands</h2>
      <b-button variant="success" @click="editBrand(null)">
        <font-awesome-icon icon="plus"></font-awesome-icon>
        New
      </b-button>
    </div>
    <hr>
    <div class="d-flex flex-column flex-md-row flex-wrap">
      <div class="card  mb-3 mr-md-3" v-for="c of brands" :key="c.id" style="width: 30em;">
        <div class="card-header">
          <span style="font-size: large; text-transform: capitalize">{{ c.name }}</span>
        </div>
        <div class="card-body">
          <div class="list-group list-group-flush">
            <div class="list-group-item">
              <a :href="c.url">{{ c.url }}</a>
            </div>
            <div class="list-group-item">
              <font-awesome-icon icon="phone"></font-awesome-icon>
              {{ c.mobile }}
            </div>
            <div class="list-group-item">
              <p style="font-size: smaller">Logo</p>
              <img :src="c.logo" style="max-height: 50px"/>
            </div>
            <div class="list-group-item">
              <p style="font-size: smaller">Logo (Dark)</p>
              <img :src="c.logoDark" style="max-height: 50px"/>
            </div>
            <div class="list-group-item">
              <p style="font-size: smaller">Theme Color(s)</p>
              <span :style="theme(c)" class="text-white p-2">
                {{ c.name }}
              </span>
            </div>


            <div class="list-group-item">
              <strong>Highlights</strong>
              <div class="row row-cols-2 justify-content-center">
                <div class="p-2 col d-flex flex-column justify-content-center align-items-center align-content-center"
                     v-for="(bh, idx) of c.brandHighlights" :key="idx">
                  <strong>{{ bh.name }}</strong>
                  <img :src="bh.icon" width="48"/>
                  <i>{{ bh.desc }}</i>
                </div>
              </div>
            </div>

            <div class="list-group-item">
              <strong>Banners</strong>
              <div class="d-flex flex-wrap justify-content-start mb-3" v-if="c.banners && c.banners.length > 0">
                <b-img-lazy class="mr-2 mb-2 border-info border" v-for="img of c.banners" :key="img.name"
                            :src="makeUrl(img.name)"
                            thumbnail
                            width="100"></b-img-lazy>
              </div>

            </div>
            <div class="list-group-item">
              <strong>Banners for Mobile</strong>
              <div class="d-flex flex-wrap justify-content-start mb-3"
                   v-if="c.bannersForMobile && c.bannersForMobile.length > 0">
                <b-img-lazy class="mr-2 mb-2 border-info border" v-for="img of c.bannersForMobile" :key="img.name"
                            :src="makeUrl(img.name)"
                            thumbnail
                            width="100"></b-img-lazy>
              </div>

            </div>



          </div>


          <div class="list-group my-3" v-if="c.productCommonImages && c.productCommonImages.length > 0">
            <div class="list-group-item list-group-item-dark">Common Product Images</div>

            <a class="list-group-item" v-for="(p, idx) of c.productCommonImages" :key="p" :href="p" target="_blank">Image {{ idx+1 }}</a>
          </div>


        </div>

        <div class="card-footer d-flex justify-content-end">
          <b-btn-group>
            <b-button variant="success" @click="editBrand(c)">
              <font-awesome-icon icon="edit"></font-awesome-icon>
              Edit
            </b-button>
          </b-btn-group>
        </div>
      </div>
    </div>
    <b-modal id="edit-brand" title="Edit Brand" @ok="saveBrand">

      <div class="row row-cols-1 row-cols-md-3">
        <div class="col">
          <b-form-group label="Name">
            <b-form-input v-model="brand.name"></b-form-input>
          </b-form-group>

        </div>
        <div class="col">
          <b-form-group label="Sub Title">
            <b-form-input v-model="brand.subTitle"></b-form-input>
          </b-form-group>

        </div>
        <div class="col">
          <b-form-group label="Host" description="enter domain, without https://">
            <b-form-input v-model="brand.url"></b-form-input>
          </b-form-group>
        </div>

        <div class="col">
          <b-form-checkbox switch v-model="brand.officialAPI">Official WA API?</b-form-checkbox>
        </div>

        <div class="col">
          <b-form-group label="Official API">
            <b-form-select v-model="brand.apiProvider" :options="waOptions"></b-form-select>
          </b-form-group>
        </div>
        <div class="col">
          <b-form-checkbox switch v-model="brand.hasStore">Has Store?</b-form-checkbox>
        </div>

        <div class="col">
          <b-form-group label="Mobile" description="only mobile number no country code stuff">
            <b-form-input v-model="brand.mobile"></b-form-input>
          </b-form-group>
        </div>

        <div class="col">
          <b-form-group label="Theme Color" description="theme color of brand ">
            <b-form-input v-model="brand.themeColor" type="color"></b-form-input>
          </b-form-group>
        </div>
        <div class="col">
          <b-form-group label="Theme Color (Font)" description="theme color (Font) of brand ">
            <b-form-input v-model="brand.themeColorFont" type="color"></b-form-input>
          </b-form-group>
        </div>

      </div>

      <div class="row row-cols-1 row-cols-md-3">
        <div class="col">

          <file-upload v-model="img"
                       title="Logo"
                       :resize="true" :idx="1"
                       :preview="brand.logo"
                       @input2="addNewImage"></file-upload>

        </div>

        <div class="col">
          <file-upload v-model="img"
                       title="Logo (Dark)"
                       :resize="true"
                       :preview="brand.logoDark"
                       :idx="2" @input2="addNewImage"></file-upload>
        </div>


      </div>

      <div class="list-group list-group-flush">
        <div class="list-group-item">
          Highlights
        </div>
        <div class="list-group-item d-flex flex-column"
             v-for="(bh, idx) of brand.brandHighlights" :key="idx">
          <div class="row justify-content-between row-cols-3 align-content-center align-items-center">
            <div class="col">
              <b-form-group label="Name">
                <b-form-input v-model="bh.name"></b-form-input>
              </b-form-group>
            </div>
            <div class="col">
              <b-form-group label="Description">
                <b-form-textarea v-model="bh.desc"></b-form-textarea>
              </b-form-group>
            </div>
            <div class="col">
              <file-upload :resize="false" :idx="idx" @input2="svgFile" :preview="bh.icon"/>
            </div>
          </div>
        </div>

        <div class="list-group-item list-group-item-light d-flex justify-content-end">
          <button @click="brand.brandHighlights.push({})" class="btn btn-success">
            New Highlight
          </button>
        </div>
      </div>

      <div class="list-group list-group-flush mt-3">
        <div class="list-group-item">
          Tags
        </div>

        <div class="list-group-item">

          <div class="row row-cols-1 row-cols-md-2">
            <div class="col">
              <b-form-group label="Google Site Verification">
                <b-form-input v-model="brand.googleSiteVerification"/>
              </b-form-group>
            </div>
            <div class="col">
              <b-form-group label="Facebook Site Verification">
                <b-form-input v-model="brand.facebookSiteVerification"/>
              </b-form-group>
            </div>
          </div>
        </div>
        <div class="list-group-item d-flex flex-column"
             v-for="(bh, idx) of brand.metaTags" :key="idx">
          <div class="row justify-content-between row-cols-2 align-content-center align-items-center">
            <div class="col">
              <b-form-group label="Name">
                <b-form-input v-model="bh.name"></b-form-input>
              </b-form-group>
            </div>
            <div class="col">
              <b-form-group label="Value">
                <b-form-input v-model="bh.value"></b-form-input>
              </b-form-group>
            </div>
          </div>
        </div>

        <div class="list-group-item list-group-item-light d-flex justify-content-end">
          <button @click="brand.metaTags.push({name: '', value: ''})" class="btn btn-success">
            New Tag
          </button>
        </div>
      </div>

      <div class="list-group list-group-flush mt-3">
        <div class="list-group-item d-flex justify-content-between align-content-center align-items-center">
          Banners
          <b-button @click.prevent="brand.banners.push({})" variant="success">
            <font-awesome-icon icon="plus"></font-awesome-icon>
          </b-button>
        </div>
        <div class="list-group-item d-flex justify-content-between align-content-center align-items-center"
             v-for="(b, idx) of brand.banners" :key="idx">
          <div class="d-flex align-content-center align-items-center">
            <b-form-input v-model="b.value" placeholder="title" class="mr-3"></b-form-input>
            <b-form-input v-model="b.desc" placeholder="Description" class="mr-3"></b-form-input>
            <b-form-input v-model="b.link" placeholder="Page Url" class="mr-3"></b-form-input>
            <file-upload :idx="idx" :preview="b.name" @input2="bannerUpload" no-title="true" cls="no-b"/>
          </div>

          <div class="d-flex align-content-center align-items-center">
            <b-button @click.prevent="brand.banners.splice(idx, 1)" variant="danger" size="sm">
              <font-awesome-icon icon="times"></font-awesome-icon>
            </b-button>
          </div>
        </div>

      </div>
      <div class="list-group list-group-flush mt-3">
        <div class="list-group-item d-flex justify-content-between align-content-center align-items-center">
          Banners For Mobile
          <b-button @click.prevent="brand.bannersForMobile.push({})" variant="success">
            <font-awesome-icon icon="plus"></font-awesome-icon>
          </b-button>
        </div>
        <div class="list-group-item d-flex justify-content-between align-content-center align-items-center"
             v-for="(b, idx) of brand.bannersForMobile" :key="idx">
          <div class="d-flex align-content-center align-items-center">
            <b-form-input v-model="b.value" placeholder="Title" class="mr-3"></b-form-input>
            <b-form-input v-model="b.desc" placeholder="Description" class="mr-3"></b-form-input>
            <b-form-input v-model="b.link" placeholder="Page Url" class="mr-3"></b-form-input>
            <file-upload :idx="idx" :preview="b.name" @input2="bannerUploadMobile" no-title="true" cls="no-b"/>
          </div>

          <div class="d-flex align-content-center align-items-center">
            <b-button @click.prevent="brand.bannersForMobile.splice(idx, 1)" variant="danger" size="sm">
              <font-awesome-icon icon="times"></font-awesome-icon>
            </b-button>
          </div>
        </div>

      </div>

      <hr>
      <file-upload v-model="img2" :resize="true" @input="addNewImageP" title="Common Product Images"></file-upload>
      <div class="d-flex flex-column justify-content-start mt-2 mb-2">

        <small>Drag and Drop to Rearrange</small>
        <draggable v-model="brand.productCommonImages" group="people">

          <transition-group>
            <div
                class="d-flex w-100 justify-content-between align-items-center align-content-center m-2 border-info p-2 border"
                v-for="img of brand.productCommonImages" :key="img">
              <b-img-lazy class="" :src="makeUrl(img)" width="80"></b-img-lazy>
              <div>
                <a @click.prevent="removeImage(img)"
                   href="#"
                   class="btn btn-outline-danger btn-sm"
                   style="cursor: pointer">
                  <font-awesome-icon icon="times" size="2x"></font-awesome-icon>
                </a>
              </div>
            </div>
          </transition-group>
        </draggable>

      </div>
    </b-modal>


  </section>
</template>
<script>
import axios from 'axios'
import FileUpload from '@/components/file-upload'
import {makeImgUrl} from '@/util'
import draggable from "vuedraggable";

export default {
  name: 'discounts',
  components: {draggable, FileUpload},
  data() {
    return {
      img: '',
      img2: '',
      brand: {
        name: '',
        url: '',
        brandHighlights: [],
        productCommonImages: []
      },
      brands: {},
      waOptions: ['wati', 'gallabox', 'interakt']
    }
  },
  computed: {},
  methods: {
    theme(c) {
      return `background-color:${c.themeColor};color:${c.themeColorFont}`
    },
    makeUrl(p) {
      if (!p) return null
      return makeImgUrl(p)
    },
    async bannerUpload({url, idx}) {
      console.log('banner', url, idx)
      this.$set(this.brand.banners[idx], 'name', url)
    },
    async bannerUploadMobile({url, idx}) {
      console.log('banner', url, idx)
      this.$set(this.brand.bannersForMobile[idx], 'name', url)
    },
    async svgFile({url, idx}) {
      console.log('svg', url, idx)
      this.$set(this.brand.brandHighlights[idx], 'icon', url)
    },
    addNewImage({url, idx}) {
      console.log(url, idx, typeof idx)
      setTimeout(() => {
        if (idx === 1)
          this.brand.logo = url
        if (idx === 2)
          this.brand.logoDark = url
      }, 500)
    },
    addNewImageP(url) {
      console.log(url, 'addNewProdImg')
      setTimeout(() => {
        if(!this.brand.productCommonImages){
          this.brand.productCommonImages = [url]
        } else {
          this.brand.productCommonImages.push(url)
        }
      }, 500)
    },

    removeImage (img) {
      let start = this.brand.productCommonImages.indexOf(img)
      console.log('remove', img, 'index', start)
      this.brand.productCommonImages.splice(start, 1)
    },
    async editBrand(c) {
      this.brand = Object.assign({}, c || {name: '', brandHighlights: [], metaTags: [], banners: [], productCommonImages: []})
      this.$bvModal.show('edit-brand')
    },
    async saveBrand() {
      let b = Object.assign({}, this.brand)
      b.brandHighlights = (b.brandHighlights || []).map(bh => {
        delete bh['logoFile']
        return bh
      })
      await axios.post('/api/admin/brands', this.brand)
      await this.getBrands()
      this.$bvModal.hide('edit-brand')
    },
    async getBrands() {
      let pds = await axios.get(`/api/admin/brands`)
      this.brands = pds.data.map(b => {
        b.logoDark = b.logoDark || b.logo
        b.brandHighlights = b.brandHighlights || [{}]
        b.metaTags = b.metaTags || [{name: '', value: ''}]
        b.banners = b.banners || [{name: '', value: ''}]
        b.bannersForMobile = b.bannersForMobile || [{name: '', value: ''}]
        b.productCommonImages = b.productCommonImages || []
        return b
      })
      await this.$store.dispatch('loadBrands')
    }
  },
  async created() {
    await this.getBrands()
  },
}
</script>
<style>
.logo-display {
  padding: 10px;
}

.logo-display svg {
  max-width: 100px;
}
</style>