<template>
  <section class="p-2">

    <div class="d-flex justify-content-between">
      <h2>Combopack</h2>

      <b-button-group>

        <b-button variant="primary" @click="getPacks()">
          Reload
        </b-button>

        <b-button variant="success" @click="editPack(null)">
          Add
        </b-button>
      </b-button-group>
    </div>

    <div class="d-flex flex-column flex-md-row flex-wrap">

      <div class="card  mb-3 mr-md-3" v-for="c of combos" :key="c.id" style="max-width: 400px">
        <div class="card-header">{{ c.name }}</div>

        <div class="card-body">
          <div class="list-group">

            <div class="list-group-item" v-for="(pl, idx) of c.productList" :key="idx">
              {{ pl.product }} &ndash; {{ pl.qty }}
            </div>

            <div class="list-group-item d-flex justify-content-between">
              <s>
                <rupee>{{ c.actualPrice }}</rupee>
              </s>
              <rupee>{{ c.salePrice }}</rupee>
            </div>
            <div class="list-group-item" v-if="c.brand">
              <display-brand :id="c.brand.id" :border="false"></display-brand>
            </div>

          </div>
        </div>
        <div class="card-footer d-flex justify-content-end">
          <b-btn-group>
            <b-button variant="success" @click="editPack(c)">
              Edit
            </b-button>
            <b-button variant="danger" @click="deletePack(c)">
              Delete
            </b-button>
          </b-btn-group>
        </div>
      </div>
    </div>
    <b-modal id="edit-code" title="Combo Pack Edit" @ok="savePack">

      <b-form-group label="Name">
        <b-form-input v-model="code.name"></b-form-input>
      </b-form-group>


      <b-form-group label="From">
        <b-datepicker v-model="code.startAt"></b-datepicker>
      </b-form-group>

      <b-form-group label="Till">
        <b-datepicker v-model="code.endAt"></b-datepicker>
      </b-form-group>

      <div class="list-group">
        <div class="list-group-item d-flex justify-content-between">
          Products
          <button class="btn btn-info" @click="items.push({})">Add</button>
        </div>
        <div class="list-group-item" v-for="(o, idx) of items"
             :key="idx">
          <b-row cols="1" cols-lg="3">
            <b-col class="mb-3 mb-md-0">
              <v-select
                  v-model="o.id"
                  :options="$store.state.products"
                  label="name" :reduce="p => p.id"></v-select>
            </b-col>
            <b-col class="mb-3 mb-md-0">
              <b-form-select v-model="o.vid"
                             :options="variants(o)"
                             value-field="id"
                             text-field="displayName"
              ></b-form-select>
            </b-col>
            <b-col>
              <b-form-input v-model="o.qty" @change="updatePrice"></b-form-input>
            </b-col>
          </b-row>

        </div>
      </div>

      <b-form-group label="Actual Price">
        <b-form-input v-model="code.actualPrice"></b-form-input>
      </b-form-group>

      <b-form-group label="Sale Price">
        <b-form-input v-model="code.salePrice"></b-form-input>
      </b-form-group>


      <file-upload v-model="img" :resize="true" @input="addNewImage"></file-upload>
      <div class="d-flex flex-column justify-content-start mt-2 mb-2">

        <small>Drag and Drop to Rearrange</small>
        <draggable v-model="code.images" group="people">

          <transition-group>
            <div
                class="d-flex w-100 justify-content-between align-items-center align-content-center m-2 border-info p-2 border"
                v-for="img of code.images" :key="img">
              <b-img-lazy class="" :src="makeImgUrl(img)" width="80"></b-img-lazy>
              <div>
                <a @click.prevent="removeImage(img)"
                   href="#"
                   class="btn btn-outline-danger btn-sm"
                   style="cursor: pointer">
                  <font-awesome-icon icon="times" size="2x"></font-awesome-icon>
                </a>
              </div>
            </div>
          </transition-group>
        </draggable>


      </div>
      <b-form-group label="Brand">
        <b-form-select v-model="code.brand.id" :options="$store.state.brands" value-field="id"
                       text-field="name"></b-form-select>
      </b-form-group>
    </b-modal>
  </section>
</template>
<script>
import axios from "axios";
import DisplayBrand from "@/components/display-brand.vue";
import Rupee from "@/components/rupee.vue";
import draggable from 'vuedraggable'
import FileUpload from "@/components/file-upload.vue";

export default {
  name: 'Combos',
  components: {DisplayBrand, Rupee, draggable, FileUpload},
  data: () => ({
    combos: [],
    code: {
      name: '',
      brand: {
        id: null
      },
      images: []
    },
    items: [],
    img: null
  }),
  async created() {
    await this.getPacks()
  },
  methods: {
    removeImage (img) {
      let start = this.code.images.indexOf(img)
      console.log('remove', img, 'index', start)
      this.code.images.splice(start, 1)
    },
    makeImgUrl (p) {
      if (p.indexOf('http') === 0) {
        if (p.indexOf('https://ms-profiles') === 0) {
          let imgName = p.replace('https://ms-profiles.objectstore.e2enetworks.net/', '')
          return `https://themilletstore.imgix.net/${imgName}?ar=1&fit=clip&height=1024&ixlib=java-2.3.2&w=181&width=1024&auto=format`
        }
        return p
      }
      return `${process.env.VUE_APP_URL}/public/image/${p}?size=128`
    },
    async addNewImage(a){
      setTimeout(() => {
        this.code.images.push(a)
      }, 1000)
    },
    async updatePrice() {
      setTimeout(() => {
        this.code.actualPrice = this.items.map(it => {
          let v = this.allVariants.filter(v => v.id === it.vid)
          return v.length === 0 ? 0 : v[0].price * it.qty
        }).reduce((a, b) => a + b, 0)
      }, 300)
    },
    variants(li) {
      if (li.id) {
        let products = this.$store.state.products
            .filter(p => p.id === li.id)
        if (products.length === 0) return []

        return products[0].variants
      }
      return []
    },
    async editPack(c0) {
      let c = c0 || {
        images: [],
        brand: {
          id: null
        }
      }
      if (c.id) {
        let item = []
        for (const [key, value] of Object.entries(c.itemMap)) {
          let pid = this.allVariants.filter(v => v.id === key)[0].productId
          item.push({
            id: pid,
            vid: key,
            qty: +value
          })
        }
        this.items = item
      } else {
        this.items = []
      }

      this.code = Object.assign({}, c)

      this.$bvModal.show('edit-code')

    },
    async getPacks() {
      this.combos = (await axios.get("/api/admin/combos")).data
    },

    async savePack() {
      this.code.itemMap = {}
      this.items.forEach(it => {
        this.code.itemMap[it.vid] = it.qty
      })
      await axios.post('/api/admin/combos', this.code)
      await this.getPacks()
      this.$bvModal.hide('edit-code')
    },


    async deletePack(c) {
      await axios.delete(`/api/admin/combos/${c.id}`)
      await this.getCodes()
    },
  },
  computed: {
    allVariants() {
      return this.$store.state.products.flatMap(p => p.variants)
    }
  }
}
</script>